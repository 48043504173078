
























import Vue from 'vue';
import AddAtkinsonHyperlegibleFont from './AddAtkinsonHyperlegibleFont.vue';
import AddCommissionFeedbackAfterFinished from './AddCommissionFeedbackAfterFinished.vue';
import AddI18nLocaleSwitcherToNavbar from './AddI18nLocaleSwitcherToNavbar.vue';
import AddMenuIcons from './AddMenuIcons.vue';
import AddMultipleCommissionRequest from './AddMultipleCommissionRequest.vue';
import AddReviewsAndRatings from './AddReviewsAndRatings.vue';
import AddToastNotifications from './AddToastNotifications.vue';
import ChangeCreateLayoutDefaultV2 from './ChangeCreateLayoutDefaultV2.vue';
import ChangeDashboardSettingsMenu from './ChangeDashboardSettingsMenu.vue';
import ChangeIdentityVerificationRequirements from './ChangeIdentityVerificationRequirements.vue';
import RemoveBrowserNotifications from './RemoveBrowserNotifications.vue';

export default Vue.extend({
  components: {
    AddAtkinsonHyperlegibleFont,
    AddCommissionFeedbackAfterFinished,
    AddI18nLocaleSwitcherToNavbar,
    AddMenuIcons,
    AddMultipleCommissionRequest,
    AddReviewsAndRatings,
    AddToastNotifications,
    ChangeCreateLayoutDefaultV2,
    ChangeDashboardSettingsMenu,
    ChangeIdentityVerificationRequirements,
    RemoveBrowserNotifications,
  },
  data() {
    return {
      v: '2020-10',
    };
  },
  computed: {
    d(): string {
      return this.$d(new Date(this.v), 'MONTH_YEAR');
    },
  },
});
